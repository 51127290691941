
import { PropSync, Watch, Prop, Ref, Component, Vue, InjectReactive, Emit } from 'vue-property-decorator';
import { presCategoryTypeList, saasServiceList, saasServiceProduct } from "@/api/prescriptionDb";

@Component({
  components: {
  },
})
export default class SaaSServiceForm extends Vue {
    @Ref("form") formRef: VForm;
    @Prop() productCategoryOptions
    @PropSync("form") dialogForm!: any;
    @PropSync("visible") dialogVisible !: boolean;

    productNameOptions = [];
    saasServiceAllOptions = [];
    saasServiceOptions = [];
    saasServiceProductOptions = [];

    saveLoading = false;
    formRules = {
        presCategoryId: { required: true, message: "请选择产品分类" },
        presId: { required: true, message: "请选择产品名称" },
        saasCategoryId: { required: true, message: "请选择SaaS服务分类" },
        saasServiceId: { required: true, message: "请选择SaaS服务产品" },
    };

    mounted() {
        this.initSaasServiceList();
    }

    @Watch("dialogForm.saasCategoryId")
    setSaasServiceProductOptions(categoryId) {
        this.saasServiceProductOptions = this.saasServiceAllOptions?.filter((el) => {
            return el.saasServiceClassId == categoryId;
        })
    }

    @Watch("dialogForm.presCategoryId")
    setProductOptions(presCategoryId) {
       if (!presCategoryId) return;
       this.getProductOptions()
    }

    initSaasServiceList() {
        saasServiceList().then((res) => {
            const list = res.data;
            this.saasServiceAllOptions = list;
            const _list = _.uniqBy(list, "saasServiceClassId");
            this.saasServiceOptions = _list;
        }).catch(() => {

        })
    }

    get getDialogTitle() {
        return "新增/编辑"
    }

    handleClose() {
        this.formRef.resetFields();
        this.productNameOptions = [];
        this.dialogVisible = false;
    }

    getProductOptions() {
        const params = {
            categoryType: 2,
            parentId: this.dialogForm.presCategoryId,
            merchantId: 0,
        }
        presCategoryTypeList(params).then((res) => {
            this.productNameOptions = res.data;
        })
    }

    fenleiChange($event) {
        this.dialogForm.presId = ""
    }

    mingchengChange() {
    }
    mingchengFocus() {
        this.getProductOptions();
    }

    saasfenleiChange() {
        this.dialogForm.saasServiceId = "";
    }

    @Emit("syncList")
    syncList() {}

    submit() {
        this.formRef.validate().then(() => {
            this.saveLoading = true;
            const { saasCategoryId, saasServiceId } = this.dialogForm;
            const saasCategoryName = this.saasServiceOptions?.find(el => el.saasServiceClassId == saasCategoryId)?.saasServiceClassName;
            const saasServiceName = this.saasServiceProductOptions?.find(el => el.saasServiceId == saasServiceId)?.saasServiceName;
            const params = { ...this.dialogForm, saasCategoryName, saasServiceName }
            saasServiceProduct(params).then((res) => {
                this.$message.success("操作成功")
                this.handleClose();
                this.syncList();
            }).catch(() => {
            }).finally(() => {
                this.saveLoading = false;
            })
        })
    }

}
